<template>
  <div
    v-if="company.length > 0 && $store.state.settings.init && init"
    class="single-company"
  >
    <a @click="$router.go(-1)" class="back-link">
      <span class="material-icons">arrow_back</span>
      <span class="text">All companies</span>
    </a>
    <h1>{{ company[0].name }}</h1>

    <!-- <button @click="fetchCompanies">Fetch</button> -->
    <div class="single-company-meta-users">
      <div class="single-company-column-left">
        <div class="table-header-small">Meta</div>

        <div class="admin-table horizontal single-company-meta-table">
          <div>
            <span class="key">accountOwner</span>
            <span class="value">{{
              getUserNameByVismaId(company[0].accountOwnerVismaId)
            }}</span>
          </div>
          <div>
            <span class="key">firebaseId</span>
            <span class="value">{{ company[0].id }}</span>
          </div>

          <div>
            <span class="key">vismaId</span>
            <span class="value">{{ company[0].vismaId }}</span>
          </div>
          <div>
            <span class="key">cvr</span>
            <span class="value">{{ company[0].cvr }}</span>
          </div>

          <div>
            <span class="key">companyCategory</span>
            <span class="value">{{
              getCategoryById(company[0].companyCategory)
            }}</span>
          </div>
          <div>
            <span class="key">{{ priceGroup1Name }}</span>
            <span class="value">{{
              getPriceGroupById(company[0].companyPriceGroup)
            }}</span>
          </div>
          <div>
            <span class="key">{{ priceGroup2Name }}</span>
            <span class="value">{{
              getPriceGroup2ById(company[0].companyPriceGroup2)
            }}</span>
          </div>
          <div>
            <span class="key">{{ priceGroup3Name }}</span>
            <span class="value">{{
              getPriceGroup3ById(company[0].companyPriceGroup3)
            }}</span>
          </div>

          <div>
            <span class="key">allowCredit</span>
            <span class="value">{{ company[0].allowCredit }}</span>
          </div>
          <div>
            <span class="key">productsPrice</span>
            <span class="value">{{ singleCompanyProductPriceString }}</span>
          </div>
          <div>
            <span class="key">productsStock</span>
            <span class="value">{{ company[0].productsStock }}</span>
          </div>
          <div>
            <span class="key">priceList</span>
            <span class="value">{{ company[0].priceList }}</span>
          </div>
          <div>
            <span class="key">deliveryRoute</span>
            <span class="value">{{ company[0].deliveryRoute }}</span>
          </div>
          <div>
            <span class="key">route</span>
            <span class="value">{{ getRouteById(company[0].route) }}</span>
          </div>
          <div>
            <span class="key">blockedDeliveryDays</span>
            <span class="value">{{
              showBlockedDays(company[0].blockedDeliveryDays)
            }}</span>
          </div>
          <div v-if="appBrand === 'kvalitetsfisk'">
            <span class="key">testGroup</span>
            <span class="value"
              >{{ company[0].testGroup }}
              <button
                v-if="company[0].testGroup === 'none'"
                @click="updateTestGroup(company[0].id, 'Test1')"
              >
                Change to Test1
              </button>
              <button v-else @click="updateTestGroup(company[0].id, 'none')">
                Change to none
              </button></span
            >
          </div>
        </div>

        <div class="table-header-small">Users</div>

        <div
          class="admin-table company-users-table"
          v-if="companyUsers.length > 0"
        >
          <div class="table-header">
            <span class="email label">Email</span>
            <span class="role label">Role</span>
          </div>
          <div
            v-for="user in companyUsers"
            :key="user.id"
            @click="navigateToUser(user.id)"
          >
            <span class="email">{{ user.email }}</span>
            <span class="role">{{ userRoles[user.role].name }}</span>
          </div>
        </div>
      </div>

      <div class="single-company-column-right">
        <div class="table-header-small">Contact</div>

        <div class="admin-table horizontal single-company-address-table">
          <div>
            <span class="key">address1</span>
            <span class="value">{{ company[0].address1 }}</span>
          </div>
          <div>
            <span class="key">address2</span>
            <span class="value">{{ company[0].address2 }}</span>
          </div>

          <div>
            <span class="key">postCode</span>
            <span class="value">{{ company[0].postCode }}</span>
          </div>

          <div>
            <span class="key">area</span>
            <span class="value">{{ company[0].area }}</span>
          </div>

          <div>
            <span class="key">contactEmails</span>
            <span class="value email">
              <span v-for="email in company[0].contactEmails" :key="email">
                {{ email }}
              </span>
            </span>
          </div>

          <div>
            <span class="key">contactPhone</span>
            <span class="value">{{ company[0].contactPhone }}</span>
          </div>

          <div>
            <span class="key">contactMobile</span>
            <span class="value">{{ company[0].contactMobile }}</span>
          </div>

          <div>
            <span class="key">sellerName</span>
            <span class="value">{{ company[0].sellerName }}</span>
          </div>
          <div>
            <span class="key">sellerEmail</span>
            <span class="value">{{ company[0].sellerEmail }}</span>
          </div>
          <div>
            <span class="key">sellerPhone</span>
            <span class="value">{{ company[0].sellerPhone }}</span>
          </div>
          <div>
            <span class="key">sellerMobile</span>
            <span class="value">{{ company[0].sellerMobile }}</span>
          </div>
        </div>
        <div class="table-header-small">Shared shortlists</div>
        <div class="admin-table shared-shortlist-table">
          <div class="table-header">
            <span class="label">Name</span>
          </div>
          <div
            class="item"
            v-for="shortlist in singleCompanySharedShortlists"
            :key="shortlist.id"
            @click="navigateToSharedShortlist(shortlist.id)"
          >
            <span>{{ shortlist.name }}</span>
          </div>
        </div>

        <div class="table-header-small">Company shortlists</div>
        <div class="admin-table shared-shortlist-table">
          <div class="table-header">
            <span class="label">Name</span>
          </div>
          <div
            class="item"
            v-for="shortlist in singleCompanyCompanyShortlists"
            :key="shortlist.id"
            @click="navigateToCompanyShortlist(shortlist.id)"
          >
            <span>{{ shortlist.name }}</span>
          </div>
        </div>

        <SingleCompanyProducts />

        <div v-if="appBrand === 'kvalitetsfisk'">
          <div class="table-header-small">
            Recommended products from: {{ company[0].testGroup }}
          </div>
          <div class="admin-table single-company-products-table">
            <div class="table-header">
              <span class="label id">Id</span>
              <span class="label name">Product</span>
            </div>
            <div v-if="company[0].testGroup == 'Control'" class="inner">
              <div
                v-for="(product, key) in company[0]
                  .recommendedShortlistProducts"
                :key="'recommended-' + key"
              >
                <span class="id">{{ key }}</span>
                <span class="name">{{
                  getProductByVismaId(key) !== undefined
                    ? getProductByVismaId(key).name
                    : "Deleted product"
                }}</span>
              </div>
            </div>
            <div
              v-else-if="
                company[0].testGroup == 'Test1' ||
                company[0].testGroup == 'Test2'
              "
              class="inner"
            >
              <div
                v-for="(product, key) in company[0].recommendedProducts"
                :key="'recommended-' + key"
              >
                <span class="id">{{ key }}</span>
                <span class="name">{{
                  getProductByVismaId(key) !== undefined
                    ? getProductByVismaId(key).name
                    : "Deleted product"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { db } from "../main";

export default {
  name: "SingleCompany",
  data() {
    return {
      search: "",
    };
  },
  mounted() {},
  methods: {
    navigateToCompanyShortlist: async function (listId) {
      this.$store.commit("companyShortlists/setSelectedShortlist", listId);
      await this.$store.dispatch("companyShortlists/bindShortlists");
      this.$store.commit("companyShortlists/setInit", true);
      await this.$store.dispatch("companyShortlists/setupEditing");
      this.$router.push({ name: "CompanyShortlists" });
    },
    navigateToSharedShortlist: async function (listId) {
      this.$store.commit("sharedShortlists/setSelectedShortlist", listId);
      await this.$store.dispatch("sharedShortlists/bindShortlists");
      this.$store.commit("sharedShortlists/setInit", true);
      await this.$store.dispatch("sharedShortlists/setupEditing");
      this.$router.push({ name: "SharedShortlists" });
    },
    navigateToUser: function (id) {
      this.$router.push({ name: "SingleUser", params: { id } });
    },
    shortlistAdd: function (id) {
      this.$store
        .dispatch("companies/shortlistAdd", {
          companyId: this.company[0].id,
          productId: id,
        })
        .then(() => {
          console.log("Add Shortlist completed successfully");
        });
    },
    shortlistRemove: function (id) {
      this.$store
        .dispatch("companies/shortlistRemove", {
          companyId: this.company[0].id,
          productId: id,
        })
        .then(() => {
          console.log("Remove company shortlist completed successfully");
        });
    },
    updateTestGroup: function (companyId, testGroup) {
      console.log(companyId);
      console.log(testGroup);
      db.collection("companies").doc(companyId).update({
        testGroup: testGroup,
      });
    },
    showBlockedDays(days) {
      if (days.length === 0) {
        return "None";
      }

      const daysMapping = {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday",
      };

      return days.map((day) => daysMapping[day]).join(", ");
    },
  },
  computed: {
    ...mapState({
      company: (state) => state.companies.singleCompany,
      companyUsers: (state) => state.companies.singleCompanyUsers,
      appBrand: (state) => state.settings.appSettings.app,
      init: (state) => state.companies.singleCompanyInit,
    }),
    ...mapGetters("settings", [
      "userRoles",
      "getCategoryById",
      "priceGroup1Name",
      "priceGroup2Name",
      "priceGroup3Name",
      "getPriceGroupById",
      "getPriceGroup2ById",
      "getPriceGroup3ById",
      "getRouteById",
    ]),

    ...mapGetters("users", ["getUserNameByVismaId"]),
    ...mapGetters("products", [
      "getProductsBySearch",
      "productCount",
      "getShortlistProducts",
      "getProductByVismaId",
    ]),
    ...mapGetters("companies", [
      "singleCompanySharedShortlists",
      "singleCompanyCompanyShortlists",
      "singleCompanyProductPriceString",
    ]),
  },

  async created() {
    await this.$store.dispatch("companies/bindSingleCompany", {
      id: parseInt(this.$route.params.id),
    });

    await this.$store.dispatch("companies/bindSingleCompanyUsers", {
      id: this.$route.params.id,
    });

    await this.$store.dispatch(
      "priceLists/bindSelectedPriceList",
      this.company[0].vismaId.toString()
    );

    this.$store.commit("companies/setSingleCompanyInit", true);
  },
  beforeDestroy() {
    this.$store.commit("companies/setSingleCompanyInit", false);
    this.$store.dispatch("companies/unbindSingleCompany");
    this.$store.dispatch("companies/unbindSingleCompanyUsers");
    this.$store.dispatch("priceLists/unbindSelectedPriceList");
  },
};
</script>
